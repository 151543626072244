


























































import {
  defineComponent,
  reactive,
  computed,
  useContext,
  useRouter
} from '@nuxtjs/composition-api'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import { IDrawerMenu } from '@/interfaces/ui'

export default defineComponent({
  setup() {
    const { $auth } = useContext()
    const router = useRouter()
    const state = reactive({
      clipped: false,
      drawer: false,
      fixed: false,
      items: [
        {
          icon: 'mdi-login',
          title: 'Login',
          to: '/login',
          authentication: false,
          order: 1
        },
        {
          icon: 'mdi-account',
          title: 'Register',
          to: '/register',
          authentication: false,
          order: 2
        },
        {
          icon: 'mdi-apps',
          title: 'Welcome',
          to: '/',
          authentication: true,
          order: 3
        },
        {
          icon: 'mdi-chart-bubble',
          title: 'Profile',
          to: '/profile',
          authentication: true,
          order: 4
        },
        {
          icon: 'mdi-crosshairs-gps',
          title: 'locations',
          to: '/locations',
          authentication: true,
          order: 5
        },
        {
          icon: 'mdi-account',
          title: 'Review Lib',
          to: '/review-lib',
          authentication: true,
          order: 7
        },
        {
          icon: 'mdi-cart',
          title: 'Categories',
          to: '/categories',
          authentication: true,
          order: 6
        }
      ] as IDrawerMenu[],
      miniVariant: false,
      right: true,
      title: 'Findyo Admin',
      config: process.env
    })

    const filteredItems: IDrawerMenu[] = computed(() => {
      const result: IDrawerMenu[] = filter(state.items, (i: IDrawerMenu) => {
        if ($auth.loggedIn) {
          return i.authentication
        } else {
          return !i.authentication
        }
      })
      const sortedList: IDrawerMenu[] = sortBy(result, ['order'])
      return sortedList
    }).value

    const logout = async () => {
      await $auth.logout()
      router.push('/login')
    }

    return {
      state,
      filteredItems,
      logout
    }
  }
})
