
















import { defineComponent, reactive } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  setup(props) {
    const state = reactive({
      pageNotFound: '404 Not Found',
      otherError: 'An error occurred'
    })

    const head = () => {
      const title =
        props.error.statusCode === 404 ? state.pageNotFound : state.otherError
      return {
        title
      }
    }

    return {
      state,
      head
    }
  }
})
