import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _20159969 = () => interopDefault(import('../pages/categories.vue' /* webpackChunkName: "pages/categories" */))
const _82861cbe = () => interopDefault(import('../pages/locations.vue' /* webpackChunkName: "pages/locations" */))
const _367999ac = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _79a6b52c = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _0f130fb0 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _ed74d9bc = () => interopDefault(import('../pages/review/post-service-questions.vue' /* webpackChunkName: "pages/review/post-service-questions" */))
const _6cd1f695 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/categories",
    component: _20159969,
    name: "categories"
  }, {
    path: "/locations",
    component: _82861cbe,
    name: "locations"
  }, {
    path: "/login",
    component: _367999ac,
    name: "login"
  }, {
    path: "/profile",
    component: _79a6b52c,
    name: "profile"
  }, {
    path: "/register",
    component: _0f130fb0,
    name: "register"
  }, {
    path: "/review/post-service-questions",
    component: _ed74d9bc,
    name: "review-post-service-questions"
  }, {
    path: "/",
    component: _6cd1f695,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
